import React from "react";
import styled from "styled-components";

function AdFormatBlock({ title, data }) {
  return (
    <Box>
      <h2>{title} Formats</h2>
      <hr />
      <ContainerCard>
        {data.map((item, j) => {
          return (
            <Card key={j}>
              <ImgContainer>
                <img
                  src={
                    title === "Mobile"
                      ? `/image/ads/${title}/${j + 1}.png`
                      : `/image/ads/${title}/${j + 1}.gif`
                  }
                  alt="ads"
                />
              </ImgContainer>
              <h2>{item}</h2>
              <Btn>View More</Btn>
            </Card>
          );
        })}
      </ContainerCard>
    </Box>
  );
}

export default AdFormatBlock;

const ContainerCard = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 1000px) {
    justify-content: center;
  }
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 0;
  & > h2 {
    position: relative;
    font-size: 2rem;
    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 0;
      left: 0;
      height: 2px;
      background-color: #363535;
    }
  }
`;
const Card = styled.div`
  max-width: 324px;
  width: 100%;
  margin: 2rem;
  h2 {
    margin-top: 1rem;
    text-align: center;
  }
`;
const ImgContainer = styled.div`
  min-height: 150px;
  width: 100%;
  img {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
  }
`;

const Btn = styled.button`
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 1rem;
  background-color: #ffff;
  border: none;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 3rem;
  font-weight: bold;
  &:hover {
    background-color: #f2f2f2;
  }
`;
