const qualities = [
  "Unique Floating Video Player",
  "Generate upto 100% Fill",
  "Live Dashboard tracking",
  " Transparent Pricing",
  "Utilisation of display ad space",
  "Higher ECPM",
  " Higher Viewability",
  " Better Yield",
];

export default qualities;
