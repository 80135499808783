import React from "react";
import HeroSection from "../components/Home/HeroSection";
import About from "../components/Home/About";
import Publisher from "../components/Home/Publisher";
import Advertiser from "../components/Home/Advertiser";

function Home() {
  return (
    <div>
      <HeroSection />
      <Publisher />
      <Advertiser />
      <About />
    </div>
  );
}

export default Home;
