const adformats = [
  {
    title: "Desktop",
    ads: ["Desktop Sticky", "Skinners"],
  },
  {
    title: "Mobile",
    ads: ["Mobile Sticky Leader board", "Mobile Video Sliders"],
  },
  {
    title: "Video",
    ads: ["Video Sliders", "In-read Videos"],
  },
];

export default adformats;
