import React from "react";
import styled from "styled-components";
import usp from "./usp";

function Usps() {
  return (
    <Container>
      <Wrapper>
        <h1>How we help you reach more customers</h1>
        {usp.map((item, n) => {
          return (
            <Card key={n}>
              <img src={`/image/icon/${n + 1}.png`} alt="" />
              <h3>{item}</h3>
            </Card>
          );
        })}
      </Wrapper>
    </Container>
  );
}

export default Usps;

const Container = styled.div`
  padding: 2rem 0 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;
const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5% 0 10%;
  h1 {
    grid-column: 1 / span 2;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    text-align: center;
  }
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    padding-left: 0;
  }
  @media screen and (min-width: 0px) and (max-width: 700px) {
    display: block;
  }
`;

const Card = styled.div`
  position: relative;
  padding: 1.25rem 20px;
  margin: 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  img {
    width: 40px;
    margin-right: 1rem;
    filter: grayscale(100%);
  }
  h3 {
    text-align: left;
    font-size: 1rem;
    margin-top: 10px;
    color: rgba(0, 0, 0, 1);
  }
`;
