import React from "react";
import styled from "styled-components";
import Button from "../../assets/Styles/Button";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();
  return (
    <Section>
      <Container>
        <ImgContainer>
          <Img src="/image/about_digiso.png" />
        </ImgContainer>
        <Wrapper>
          <h1>About Digiso</h1>
          <p>
            We are a global organization having its roots deep in APAC working
            across various platforms, ways and using modern technique to
            generate the desired outcome at a way lower cost.
          </p>
          <Btn onClick={() => navigate("/about")}>Learn More</Btn>
        </Wrapper>
      </Container>
    </Section>
  );
}

export default About;

const Section = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow-x: hidden;
  padding: 2rem 0;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10%;
  align-items: center;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const Wrapper = styled.div`
  flex: 1;
  h1 {
    font-size: 3rem;
    font-weight: regular !important;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 400px) {
      margin-top: 1rem;
    }
  }
  p {
    margin: 1.5rem 0;
    word-break: keep-all;
    line-height: 1.5rem;
    font-family: ${(p) => p.theme.fonts.text};
  }
`;
const ImgContainer = styled.div`
  flex: 1;

  width: 50%;
  display: grid;
  place-items: center;
`;
const Img = styled.img`
  width: 80%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const Btn = styled(Button)`
  @media screen and (max-width: 1024px) {
    text-align: center;
    display: block;
    width: 100%;
  }
`;
