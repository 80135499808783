import React from "react";
import styled from "styled-components";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Section>
      <Wrapper>
        <Container>
          <Group>
            <Link to="/about">About Us</Link>
            <a href="https://blog.digiso.ai">Blog</a>
            <a href="https://digiso.ai/contact/index.php">Contact Us</a>
            <Link to="privacy-policy">Privacy Policy</Link>
          </Group>
          <Group>
            <p>Contacts</p>
            <p>
              <b>Company Legal Name : Digiso Media</b>
            </p>
            <p>
              <b>Address:</b>
            </p>

            <p>256-1 Wd-12 Nav Vihar Colony</p>
            <p>Paonta Sahib HP 173025</p>
            <p>
              <b>Main Contact : Manisha Joshi</b>
            </p>
            <a href="tel:+91-8239092167">+91-8894161737</a>
            <a href="tel:+44-7380518041">+44-7380518041</a>
            <a href="mailto:info@digiso.ai">Mail: info@digiso.ai</a>
          </Group>
        </Container>
        <hr />
        <Icons>
          <Icon
            href="https://www.linkedin.com/company/digiso-media-llc/"
            target="_blank"
          >
            <FaLinkedin />
          </Icon>
          <Icon
            href="https://www.instagram.com/digisomedia_llc/"
            target="_blank"
          >
            <FaInstagram />
          </Icon>
        </Icons>
        <Text href="https://prarambhinfotech.com/" target="_blank">
          Copyright © 2023 Digiso ,Powered By Prarambh Infotech
        </Text>
      </Wrapper>
    </Section>
  );
}

export default Footer;

const Section = styled.footer`
  background-color: ${(p) => p.theme.color.dark};
  color: #fff;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5vw 8vw;
  align-items: center;
  hr {
    width: 100%;
    margin: 2rem 0;
  }
`;
const Container = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Group = styled.div`
  @media screen and (min-width: 0) and (max-width: 1024px) {
    width: 50%;
  }
  p {
    margin: 1rem 0;
  }
  a {
    margin: 1rem 0;
    text-decoration: none;
    color: white;
    text-decoration: none;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Icons = styled.div`
  display: flex;
`;
const Icon = styled.a`
  font-size: 2rem;
  margin: 0 1.5rem;
  color: white;
  &:hover {
    transform: scale(1.2);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
    cursor: pointer;
  }
`;

const Text = styled.a`
  margin-top: 2rem;
  text-align: center;
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
