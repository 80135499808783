import React, { useState } from "react";
import HamburgerMenu from "../components/HamburgerMenu";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "../layouts/Footer";

function Layout() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Header isOpen={isOpen} setOpen={setOpen} />

      <HamburgerMenu isOpen={isOpen} setOpen={setOpen} />
      <Outlet />

      <Footer />
    </>
  );
}

export default Layout;
