import React from "react";
import styled from "styled-components";
import qualities from "./qulality";

function Qualities() {
  return (
    <Container>
      <h1> How We Differ</h1>
      <ContainerInner>
        <ImgContainer>
          <img alt="" src="/image/advantage.png" />
        </ImgContainer>
        <Wrapper>
          <h1>Our USPs</h1>
          {qualities.map((item, n) => {
            return (
              <Card key={n}>
                <img src={`/image/icons/${n + 1}.png`} alt="" />
                <h3>{item}</h3>
              </Card>
            );
          })}
        </Wrapper>
      </ContainerInner>
    </Container>
  );
}

export default Qualities;

const Container = styled.div`
  padding: 2rem 0 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
`;
const ContainerInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 300px;
  img {
    width: 90%;
    max-width: 500px;
  }
`;
const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 10%;
  h1 {
    grid-column: 1 / span 2;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    padding-left: 0;
  }
  @media screen and (min-width: 0px) and (max-width: 700px) {
    display: block;
  }
`;

const Card = styled.div`
  position: relative;
  padding: 1.25rem 20px;
  margin: 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  img {
    width: 40px;
    margin-right: 1rem;
    filter: grayscale(100%);
  }
  h3 {
    text-align: left;
    font-size: 1rem;
    margin-top: 10px;
    color: rgba(0, 0, 0, 1);
  }
`;
