import React from "react";
import AboutSection from "../components/About/AboutSection";
import styled from "styled-components";

function About() {
  return (
    <div>
      <AboutSection
        orientation={"left"}
        title={"About Digiso"}
        image={"aboutus.png"}
        description={
          "We Digiso belive that we should let all our results speakly forces so we are majorly focused on generaly required outputs not only by using Artificial Intelligence but also allingly it to our teams proper review manual thus leading to a better outcome "
        }
      />
      <AboutSection
        orientation={"right"}
        image={"about.avif"}
        title={"Our Vision"}
        description={
          "We are curretly helping various organizations from various sectors to not only select the programmatic market but also specialise over it to generate maximun revenue"
        }
      />

      <Card>
        <h1>Our Presence</h1>
        <p>
          We are available to Assist you in UAE, India and UK and also our
          representative work their presence around the globe
        </p>
        <Map>
          <img src="/image/map.png" alt="" />
        </Map>
      </Card>
    </div>
  );
}

export default About;

const Card = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
  h1 {
    font-size: 3rem;
    font-weight: regular !important;
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 1rem;
    word-break: keep-all;
    line-height: 1.5rem;
    font-family: ${(p) => p.theme.fonts.text};
    text-align: center;
    width: 90%;
  }
`;

const Map = styled.div`
  padding: 3rem 0;
  width: 100vw;
  display: grid;
  place-items: center;
  img {
    width: 90%;
    max-width: 1000px;
  }
`;
