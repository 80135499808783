import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { RxHamburgerMenu } from "react-icons/rx";

function Header({ setOpen }) {
  const [sticky, setSticky] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSticky = () => {
      if (window.scrollY > 200) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener("scroll", handleSticky);
  }, []);
  return (
    <Nav sticky={sticky}>
      <Logo onClick={() => navigate("/")} src="/image/digiso.png" />

      <Navs>
        <Anchor selected={pathname === "/about"} to="/about">
          About Us
        </Anchor>
        <Anchor selected={pathname === "/how-we-differ"} to="/how-we-differ">
          How we differ
        </Anchor>
        <Anchor selected={pathname === "/ad-formats"} to="/ad-formats">
          Ad Formats
        </Anchor>
        <Anchor as="a" href="https://blog.digiso.ai">
          Blogs
        </Anchor>
        <Anchor as="a" href="https://digiso.ai/contact/index.php">
          Contact Us
        </Anchor>
      </Navs>
      <Menu>
        <RxHamburgerMenu onClick={() => setOpen(true)} />
      </Menu>
    </Nav>
  );
}

export default Header;

const Nav = styled.header`
  display: flex;
  max-width: 100vw;
  justify-content: space-between;
  padding: 1rem 5%;
  align-items: center;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  @media screen and (min-width: 1024px) {
    background-color: #fff;
    position: ${(p) => (p.sticky === true ? "fixed" : "static")};
  }
`;

const Logo = styled.img`
  height: 2.5rem;
  cursor: pointer;
`;

const Navs = styled.nav`
  display: flex;
  align-items: center;
`;

const Anchor = styled(Link)`
  text-decoration: none;
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #000;
  position: relative;
  ${(props) =>
    props.selected === true &&
    css`
      border-radius: 500px;
      border: 1px solid rgba(0, 0, 0, 0.7);
    `}

  &:hover {
    color: ${(p) => p.theme.color.main};
  }

  @media screen and (min-width: 0px) and (max-width: 1024px) {
    display: none;
  }
`;

const Menu = styled.div`
  display: none;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    display: block;
  }
  svg {
    font-size: 2.5rem;
  }
`;
