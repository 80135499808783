import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

function Error() {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate("/")}>
      <div>
        <Img src="/image/error_page.png" />
        <Link to="/">Home</Link>
      </div>
    </Container>
  );
}

export default Error;

const Container = styled.div`
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      color: white;
      padding: 1rem 2.5rem;
      text-decoration: none;
      border-radius: 100px;
      font-weight: bold;
      background-color: ${(p) => p.theme.color.main};
      max-width: 150px;
      &:hover {
        background-color: ${(p) => p.theme.color.dark};
      }
    }
  }
`;
const Img = styled.img`
  width: 80%;
`;
