import React from "react";
import Qualities from "../components/How-we-differ/Qualities";
import styled from "styled-components";
import Offer from "../components/How-we-differ/Offer";
import Usps from "../components/How-we-differ/Usps";

function HowWeDiffer() {
  return (
    <Wrapper>
      <Qualities />
      <Offer />
      <Usps />
    </Wrapper>
  );
}

export default HowWeDiffer;

const Wrapper = styled.div`
  background-color: #fff;
`;
