const usp = [
  "Unique funnel soritng system",
  "Better reach understanding",
  "Technologic Advancement & powerfull tools",
  "Data Analysis",
  "Real time biding & Header Biding",
  "Understanding & Reasearch on each & every publishers Inventory ",
];

export default usp;
