import React from "react";
import styled from "styled-components";

function AboutSection({ orientation, title, description, image }) {
  return (
    <Section>
      <Container orientation={orientation}>
        <ImgContainer>
          <Img src={`/image/${image}`} />
        </ImgContainer>
        <Wrapper>
          <h1>{title}</h1>
          <p>{description}</p>
        </Wrapper>
      </Container>
    </Section>
  );
}

export default AboutSection;

const Section = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  overflow-x: hidden;
`;
const Container = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.orientation === "left" ? "row" : "row-reverse")};
  width: 100%;
  padding: 0 10%;
  align-items: center;
  @media screen and (min-width: 0px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const Wrapper = styled.div`
  flex: 1;
  @media screen and (max-width: 1024px) {
    margin-bottom: 1rem;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: regular !important;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 1024px) {
      margin-top: 1rem;
    }
  }
  p {
    margin-bottom: 1rem;
    word-break: keep-all;
    line-height: 1.5rem;
    font-family: ${(p) => p.theme.fonts.text};
    white-space: pre-line;
  }
`;
const ImgContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
`;
const Img = styled.img`
  width: 90%;
`;
