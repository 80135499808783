import React from "react";
import {
  Section,
  Container,
  Wrapper,
  Btn,
  BtnL,
  Buttons,
  Img,
  Shape,
  ImgContainer,
} from "../../assets/Styles/HeroSection";
import styled from "styled-components";

function HeroSection() {
  const scrollTo = (type) => {
    if (type === "publisher") {
      const element = document.getElementById("publisher");
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } else {
      const element = document.getElementById("advertiser");
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  return (
    <TopSection>
      <Container>
        <Wrapper>
          <h1>Unique Ad Solutions</h1>
          <p>
            Programattic is our Ethos and we truly believe to make it a better
            prospect for both the ends of the programattic by creatively a
            transparent ecosystem which will help advertiser generate maximun
            for their can pays and publisher to generate maximun revenue for the
            Ad Scots.
          </p>
          <Buttons>
            <BtnL onClick={() => scrollTo("publisher")}>Publisher</BtnL>
            <Btn onClick={scrollTo}>Advertiser</Btn>
          </Buttons>
        </Wrapper>
        <ImgContainer>
          <Img src="/image/hero.png" effect="blur" alt="image" />
        </ImgContainer>
      </Container>
      <Shape src="/image/Shape_Web.png" effect="blur" />
    </TopSection>
  );
}

export default HeroSection;

const TopSection = styled(Section)`
  padding-bottom: 5%;
`;
