import React from "react";
import styled from "styled-components";
import offers from "./offers";

function Offer() {
  return (
    <Container>
      <h1> What We Offer</h1>
      <p>Reach more customers with our powerful tools.</p>
      <Wrapper>
        {offers.map((item, n) => {
          return (
            <Card key={n}>
              <img src={`/image/offer/${n + 1}.png`} alt="" />
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </Card>
          );
        })}
      </Wrapper>
    </Container>
  );
}

export default Offer;

const Container = styled.div`
  padding: 3rem 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  text-align: center;
  h1 {
    margin-bottom: 1rem;
  }
  p {
    color: rgba(0, 0, 0, 0.75);
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
`;

const Card = styled.div`
  width: 300px;
  margin: 0 1rem;
  padding: 3rem 1rem;
  position: relative;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border: 2px solid rgba(0, 0, 0, 0.56);
  margin-bottom: 2rem;
  @media and screen and (max-width: 1024px) {
    padding: 0rem 0;
  }
  img {
    width: 80px;
    margin-bottom: 5px;
    padding: 15px;
    border-radius: 10px;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.75);
    position: relative;
  }
  p {
    margin-top: 1rem;
    font-size: 1rem;
  }
  &:after {
    content: "";
    background-color: ${(p) => p.theme.color.main};
    height: 8px;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    display: none;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:hover:after {
    display: block;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: scale(1.05);
  }
`;
