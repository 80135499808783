const offers = [
  {
    title: "Header Bidding",
    description:
      "We are also in process of develope our own adapter for this technology to take away ahead",
  },
  {
    title: "JS based Integration",
    description: "We use hardcode or google ad may to cater the requirements",
  },
  {
    title: "Open RTB",
    description: "we have ad kernel & Bids cube to deliver the outcome via RTB",
  },
];

export default offers;
